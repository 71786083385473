<template>
  <div class="bodycont">

    <comHeader />
    <!-- <div class="main">
      <navTitle :navData='navData' />
    </div> -->
    <!-- 成果列表展示页面 -->
    <div class="pcont">
      <!-- <div class="rightcont"> -->
      <div class="news_list">
        <span @click="changindex1(8)" :class="cindex==8?'cur':''">全部</span>
        <span @click="changindex(index)" :class="cindex==index?'cur':''"
          v-for="(item,index) in typeList">{{item.value}}</span>
        <!-- </div> -->
      </div>
      <div class="leftcont" v-if="amType==0">
        <div class="search">
          <classifyType @getType1='getType1' @getType2='getType2' @getType3='getType3' :totalCount='totalCount1'
            @getaName='getaName' :amType='amType' />
        </div>
        <div class="datacont datacontV" id="scrollDiv" v-if="getAchieveData.length>0">
          <!-- <div class="linedata" :class="mindex==index?'activecur':''" v-for="(item,index) in getAchieveData"
            @click="changeDataV(item,index)">
            <span class="icontext">{{index + 1}}</span>
            <span class="iname">{{item.aname}}({{item.aoverview}})</span>
            <span class="inames">成果单位:{{item.achAddress}} | 成果人:{{item.userName}}</span>
            <span class="iarrow">
              <i class="el-icon-arrow-right"></i>
            </span>
          </div> -->
          <div class="continfo">
            <img :src="rowinfo.file?base_url+rowinfo.file:nodata" />
            <div class="infobox" @click.stop="changeDataV(rowinfo)">
              <div class="boxtop">
                <h3>{{rowinfo.aName}}</h3>
              </div>

              <div class="cvinfo">
                <div class="c_info">
                  <div class="cw100">
                    <div class="w100">
                      <span class="atitle">应用领域:</span>
                      {{rowinfo.application}}
                    </div>
                  </div>
                  <div class="cw100">
                    <div class="w100">
                      <span class="atitle">学科领域:</span>
                      {{rowinfo.subject}}
                    </div>
                  </div>
                  <div class="cw100">
                    <div class="w100">
                      <span class="atitle">合作方式:</span>
                      {{rowinfo.amCooperationName}}
                    </div>

                  </div>
                  <div class="cw100">
                    <div>
                      <span class="atitle">成熟度:</span>
                      {{rowinfo.mature}}
                    </div>
                    <div>
                      <span class="atitle">创新性:</span>
                      {{rowinfo.innovate}}
                    </div>
                    <div>
                      <span class="atitle">先进性:</span>
                      {{rowinfo.advanced}}
                    </div>
                    <div>
                      <span class="atitle">浏览次数:</span>
                      {{datainfo.avisits || 0}}次
                    </div>
                  </div>
                </div>
                <div class="cr100">
                  <div class="cw100">
                    <span class="atitle">项目概述:</span>
                    <div>{{rowinfo.aOverview}}</div>
                  </div>
                </div>
              </div>
              <div class="cvinfo" v-if="false">
                <div class="c_infos">
                  <div class="cw100">
<!--                    <div>-->
<!--                      <span class="atitle">第一成果完成人:</span>-->
<!--                      {{datainfo.teacher||'暂无'}}-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <span class="atitle">主要参与人:</span>-->
<!--                      {{datainfo.otherParticipants||'暂无'}}-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <span class="atitle">成果估值:</span>-->
<!--                      {{datainfo.achievementsValuation||'暂无'}}-->
<!--                    </div>-->
                   <!-- <div>
                      <span class="atitle">标签:</span>
                      {{datainfo.tag||'暂无'}}
                    </div> -->
                  </div>
                  <div class="cw100">
<!--                    <div>-->
<!--                      <span class="atitle">技术优势:</span>-->
<!--                      {{datainfo.advantage||'暂无'}}-->
<!--                    </div>-->
                    <!-- <div>
                      <span class="atitle">专利/荣誉情况:</span>
                      {{datainfo.patent||'暂无'}}
                    </div> -->

<!--                    <div>-->
<!--                      <span class="atitle">成果应用情况:</span>-->
<!--                      {{datainfo.achievementsUsage||'暂无'}}-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="cinfo1">
                <div class="cdiv">详情>>></div>
              </div>
              <div class="cinfo">
                <div @click.stop="getnext(rowinfo,1)" class="cdiv">上一项</div>
                <div @click.stop="getnext(rowinfo,2)" class="cdiv">下一项</div>
              </div>

            </div>
          </div>
          <div class="datacont" id="scrollCont">
            <div class="linedata" :class="dindex1==index?'activecur':''" v-for="(item,index) in getAchieveData"
              @click="changeData1(item,index)">
              <!-- <span class="icontext">{{index + 1}}</span> -->
              <span class="iname">{{item.aName}}</span>
              <span class="inames">成果单位:{{item.deptName}} | 成果人:{{item.person}}</span>
              <span class="iarrow">
                <i v-if="dindex1!=index" class="el-icon-arrow-down"></i>
                <i v-if="dindex1==index" class="el-icon-arrow-up"></i>
              </span>
            </div>
          </div>

        </div>
        <div v-if="getAchieveData.length==0&&isshow==true">
          <div>
            <div style="margin: 0 auto;width: 100px;">
              <img style="width: 100px;height: 100px;" src="../../assets/images/datano.png" />
            </div>
            <p style="text-align: center;">暂无数据</p>
          </div>
        </div>
        <div class="pages">
          <el-pagination v-if="totalCount1>10&&getAchieveData.length" background layout="prev, pager, next"
            :current-page="page.page1" :page-size="page.limit1" :total="totalCount1"
            @current-change="handleCurrentChange1"></el-pagination>
        </div>
      </div>


      <div class="leftcont"
        v-if="amType==1||amType==2||amType==3||amType==4||amType==5||amType==6||amType==7||amType==8">
        <div class="search">
          <classifyType @getType1='getType1' @getType2='getType2' @getType3='getType3' :amType='amType'
            :totalCount='totalCount' @getaName='getaName' />
        </div>
        <div v-if="dataList.length>0">
          <div class="lcont">
            <div class="continfo">
              <img :src="datainfo.imgUrl?base_url+datainfo.imgUrl:nodata" />
              <div class="infobox" @click.stop="tourl(datainfo)">
                <div class="boxtop">
                  <h3>{{datainfo.aname}}</h3>
                </div>

                <div class="cvinfo">
                  <div class="c_info">
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">应用领域:</span>
                        {{datainfo.application}}
                      </div>
                    </div>
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">学科领域:</span>
                        {{datainfo.subject}}
                      </div>
                    </div>
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">合作方式:</span>
                        {{datainfo.amCooperationName}}
                      </div>

                    </div>
                    <div class="cw100">
                      <div>
                        <span class="atitle">成熟度:</span>
                        {{datainfo.mature}}
                      </div>
                      <div>
                        <span class="atitle">创新度:</span>
                        {{datainfo.innovate}}
                      </div>
                      <div>
                        <span class="atitle">先进度:</span>
                        {{datainfo.advanced}}
                      </div>
                      <div>
                        <span class="atitle">浏览次数:</span>
                        {{datainfo.avisits || 0}}次
                      </div>
                    </div>
                  </div>
                  <div class="cr100">
                    <div class="cw100">
                      <span class="atitle">成果概述:</span>
                      <div>{{datainfo.aoverview}}</div>
                    </div>
                  </div>
                </div>
               <div class="cvinfo">
                 <div class="c_infos">
                   <div class="cw100">
<!--                     <div>-->
<!--                       <span class="atitle">第一成果完成人:</span>-->
<!--                       {{datainfo.teacher||'暂无'}}-->
<!--                     </div>-->
<!--                     <div>-->
<!--                       <span class="atitle">主要参与人:</span>-->
<!--                       {{datainfo.otherParticipants||'暂无'}}-->
<!--                     </div>-->
<!--                     <div>
                       <span class="atitle">成果估值:</span>
                       {{datainfo.achievementsValuation||'暂无'}}
                     </div>
                     <div>
                       <span class="atitle">标签:</span>
                       {{datainfo.tag||'暂无'}}
                     </div> -->
                   </div>
                   <div class="cw100">
<!--                     <div>-->
<!--                       <span class="atitle">技术优势:</span>-->
<!--                       {{datainfo.advantage||'暂无'}}-->
<!--                     </div>-->
                   <!--  <div>
                       <span class="atitle">专利/荣誉情况:</span>
                       {{datainfo.patent||'暂无'}}
                     </div> -->

<!--                     <div>-->
<!--                       <span class="atitle">成果应用情况:</span>-->
<!--                       {{datainfo.achievementsUsage||'暂无'}}-->
<!--                     </div>-->
                   </div>
                 </div>
               </div>
                <div class="cinfo1">
                  <div class="cdiv">详情>>></div>
                </div>
                <div class="cinfo">
                  <div @click.stop="getnextData(datainfo,1)" class="cdiv">上一项</div>
                  <div @click.stop="getnextData(datainfo,2)" class="cdiv">下一项</div>
                </div>

              </div>
              <div class='statusdiv' v-if="datainfo.isConversion">{{datainfo.isConversion==true?'已转化':''}}</div>
            </div>

          </div>
          <div class="datacont" id="scrollCont">
            <div class="linedata" :class="dindex==index?'activecur':''" v-for="(item,index) in dataList"
              @click="changeData(item,index)">
              <span class="icontext">{{index + 1}}</span>
              <span class="iname">{{item.name}}</span>
              <span class="inames">成果所属单位:{{item.achAddress}} | 成果联系人:{{item.achName}}</span>
              <span class="iarrow">
                <i v-if="dindex!=index" class="el-icon-arrow-down"></i>
                <i v-if="dindex==index" class="el-icon-arrow-up"></i>
              </span>
            </div>
          </div>
        </div>
        <div v-if="dataList.length==0&&isshow1">
          <div>
            <div style="margin: 0 auto;width: 100px;">
              <img style="width: 100px;height: 100px;" src="../../assets/images/datano.png" />
            </div>
            <p style="text-align: center;">暂无数据</p>
          </div>

        </div>
        <div class="pages">
          <el-pagination v-if="totalCount>10&&dataList.length" background layout="prev, pager, next"
            :current-page="page.page" :page-size="page.limit" :total="totalCount" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>


    </div>


    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import classifyType from '@/components/classify.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      classifyType,
      navTitle
    },
    data() {
      return {
        nodata: require('../../assets/images/infonodata.png'),
        form: {},
        cindex: 8,
        navData: [{
          name: '科技成果',
          url: 'tec',
        }, ],
        typeList: [],
        datainfo: {

        },
        rowinfo: {

        },
        dataList: [],
        dindex1: 0,
        dindex: 0,
        amType: 8,
        amFieldList: '',
        amApplicationList: '',
        amCooperationList: [],
        getAchieveData: [],


        aId: '',
        isshow: false,
        isshow1: false,
        mindex: -1,
        base_url: api.base_url,

        totalCount: 0,
        page: {
          page: 1,
          limit: 10,
          page1: 1,
          limit1: 20,
        },

        totalCount1: 0,
        aName: '',
        isaName: false,

      }
    },
    created() {

    },
    mounted() {



      // this.getTypeList()
      this.getTypeList()
      // this.getList()
      // this.getAchieve()
      this.getList1()
    },
    methods: {
      //获取关键词搜索
      getaName(data) {
        console.log(data, '=========')
        if (this.amType == 0) {
          this.aName = data
          this.getAchieveData = []
          this.page.page1 = 1
          this.getAchieve()
        } else if (this.amType == 8) {
          this.aName = data
          this.dataList = []
          this.page.page = 1
          this.getList1()
        } else {
          this.aName = data
          this.dataList = []
          this.page.page = 1
          this.getList()
        }

      },
      getAchieve() {
        let data = {
          page: this.page.page1,
          limit: this.page.limit1,
        }
        if (this.aName) {
          data.aName = this.aName
        }
        if (this.amFieldList) {
          data.amFieldList = this.amFieldList
        }
        if (this.amApplicationList) {
          data.amApplicationList = this.amApplicationList
        }
        if (this.amCooperationList) {
          data.amCooperationList = this.amCooperationList
        }
        postRequest(api.gethomePage, data).then(res => {
          this.totalCount1 = Number(res.page.totalCount)
          this.getAchieveData = res.page.list
          if (this.getAchieveData.length > 0) {
            this.rowinfo = this.getAchieveData[0]
            console.log(this.rowinfo, '=======')
          }

          if (this.getAchieveData.length == 0) {

            setTimeout(() => {
              this.isshow = true
            }, 1000)
          }
        })
      },
      //获取字典列表
      getTypeList() {
        let data = {
          page: 1,
          limit: 100,
          filterName: '成果类型'
        }
        getRequst(api.sysTypeList, data).then(res => {
          this.typeList = res.page.list || []
          this.typeList.unshift({
            value: '项目成果'
          })
          console.log('项目成果',typeList)
        })
      },
      //获取学科领域
      getType1(data) {
        if (data) {
          if (this.amType == 0) {
            this.amFieldList = data
            this.getAchieveData = []
            this.page.page1 = 1
            this.getAchieve()
          } else {
            this.amFieldList = data
            this.dataList = []
            this.page.page = 1
            this.getList()
          }
        }

      },
      //获取应用领域
      getType2(data) {
        if (data) {
          if (this.amType == 0) {
            this.amFieldList = data
            this.getAchieveData = []
            this.page.page1 = 1
            this.getAchieve()
          } else {
            this.amApplicationList = data
            this.dataList = []
            this.page.page = 1
            this.getList()
          }
        }

      },
      //获取合作方式
      getType3(data) {
        if (data) {
          if (this.amType == 0) {
            this.amCooperationList = data
            this.getAchieveData = []
            this.page.page1 = 1
            this.getAchieve()
          } else {
            this.amCooperationList = data
            this.dataList = []
            this.page.page = 1
            this.getList()
          }
        }

      },

      handleCurrentChange(val) {
        this.page.page = val;
        this.getList()
      },
      handleCurrentChange1(val) {
        this.page.page1 = val;
        this.getAchieve()
      },
      getList1() {
        let data = {
          page: this.page.page,
          limit: this.page.limit,
        }
        if (this.aName) {
          data.aName = this.aName
        }
        postRequest(api.getPageList, data).then(res => {
          if (res.code == 0) {
            this.totalCount = Number(res.page.totalCount)
            this.dataList = res.page.list
            console.log(this.dataList)
            if (res.page.list) {
              if (this.dataList.length > 0) {
                this.datainfo = this.dataList[0]
                this.dataList.forEach(item => {
                  item.filesList.forEach(items => {
                    if (items.msrIsMaster == 1) {
                      item.imgUrl = items.msrUrlStatic
                    } else {
                      item.imgUrl = item.filesList[0].msrUrlStatic
                    }
                  })
                })
              }
            }
            if (this.dataList.length == 0) {
              setTimeout(() => {
                this.isshow1 = true
              }, 500)
            }

          }
        })
      },
      //查询前台成果详情列表
      getList() {
        let data = {
          page: this.page.page,
          limit: this.page.limit,
          amAuditStatue: 4,
        }
        if (!this.amType) {
          data.profileType = ''
        }
        if (this.amType == 0) {
          data.profileType = 1
        }
        if (this.amType == 1 || this.amType == 2 || this.amType == 3 || this.amType == 4 || this.amType == 5 || this
          .amType == 7) {
          data.amType = this.amType
          data.aId = this.aId
          if (data.aId) {
            data.profileType = 1
          } else {
            data.profileType = 2
          }
          data.isReward = ''
        }
        if (this.amType == 1 || this.amType == 2 || this.amType == 3) {
          data.patentType = this.amType
        }
        if (this.amType == 6) {
          data.isReward = 1
          data.aId = this.aId
          if (data.aId) {
            data.profileType = 1
          } else {
            data.profileType = 2
          }
        }

        if (this.amFieldList) {
          data.amFieldList = this.amFieldList
        }
        if (this.amApplicationList) {
          data.amApplicationList = this.amApplicationList
        }
        if (this.amCooperationList) {
          data.amCooperationList = this.amCooperationList
        }
        if (this.aName) {
          data.aName = this.aName
        }

        postRequest(api.getPageList, data).then(res => {
          if (res.code == 0) {
            this.totalCount = Number(res.page.totalCount)
            this.dataList = res.page.list
            if (res.page.list) {
              if (this.dataList.length > 0) {
                this.datainfo = this.dataList[0]
                this.dataList.forEach(item => {
                  item.filesList.forEach(items => {
                    if (items.msrIsMaster == 1) {
                      item.imgUrl = items.msrUrlStatic
                    } else {
                      item.imgUrl = item.filesList[0].msrUrlStatic
                    }
                  })
                })
              }
            }
            if (this.dataList.length == 0) {
              setTimeout(() => {
                this.isshow1 = true
              }, 500)
            }

          }
        })
      },


      //点击查看上一条下一条数据
      //点击查看上一条下一条数据
      getnextData(data, type) {
        if (type == 1) {
          if (this.dindex == 0) {
            this.$message.info('已经是第一条数据了!')
          } else {
            this.dataList.forEach((item, index) => {
              if (item.id == data.id) {
                this.dindex = index - 1
              }
            })
            this.datainfo = this.dataList[this.dindex]
          }
        } else {
          if (this.dataList.length - 1 != this.dindex) {
            this.dataList.forEach((item, index) => {
              if (item.id == data.id && ((index + 1) >= 0)) {
                this.dindex = index + 1
              }
            })
            this.datainfo = this.dataList[this.dindex]
          } else {
            this.$message.info('暂无更多数据!')
          }
        }

      },

      getnext(data, type) {
        if (type == 1) {
          if (this.dindex1 == 0) {
            this.$message.info('已经是第一条数据了!')
          } else {
            this.getAchieveData.forEach((item, index) => {
              if (item.aId == data.aId) {
                this.dindex1 = index - 1
              }
            })
            this.rowinfo = this.getAchieveData[this.dindex1]
          }
        } else {
          if (this.getAchieveData.length - 1 != this.dindex1) {
            this.getAchieveData.forEach((item, index) => {
              if (item.aId == data.aId && ((index + 1) >= 0)) {
                this.dindex1 = index + 1
              }
            })
            this.rowinfo = this.getAchieveData[this.dindex1]
          } else {
            this.$message.info('暂无更多数据!')
          }
        }

      },



      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/resultDetails',
          query: {
            id: data.id,
            url: 'resultsinfo',
            name: '科技成果',
            amType: data.amType,
            isConversion: data.isConversion,
          }
        });

      },
      changindex1(index) {
        this.cindex = index
        this.amType = index
        this.isshow1 = false
        this.dataList = []
        this.page.page = 1
        this.isaName = true
        this.getList1()
      },
      //切换分类筛选
      changindex(index) {
        this.cindex = index
        this.amType = index
        this.amCooperationList = []
        this.isaName = true

        if (this.amType == 0) {
          this.mindex = -1
          this.aId = ''
          this.isshow = false
          this.page.page1 = 1
          this.getAchieveData = []
          this.getAchieve()
        } else {
          this.isshow1 = false
          this.dataList = []
          this.page.page = 1
          this.getList()
        }

      },
      //切换选择数据
      changeData1(item, index) {
        this.rowinfo = item
        this.dindex1 = index
        this.$forceUpdate()
      },
      //切换选择数据
      changeData(item, index) {
        this.datainfo = item
        this.dindex = index
        this.$forceUpdate()
      },
      //选择成果概况数据
      changeDataV(item, index) {
        let data = {
          id: item.aId,
          url: 'resultsinfo',
          name: '科技成果',
        }
        if (this.amType == 0) {
          data.amType = 0
        }
        this.$router.push({
          path: '/resultallDetails',
          query: data
        });
      },



    }
  }
</script>

<style lang="less" scoped>
  .bodycont {
    // background: url('../../assets/images/homebg1.png') repeat-x;
    width: 100%;
  }

  .main {
    width: 1180px;
    margin: 0px auto 0;
  }

  .site_h2 {
    height: 80px;
    width: 350px;
    margin: 0 auto;
    line-height: 80px;
    text-align: center;

    span {
      font-size: 24px;
      padding: 0 15px;
      background: #fff;
    }
  }

  .pages {
    margin: 20px auto;
    text-align: center;
  }

  .cont1 {
    min-height: 300px;
  }

  .pcont {
    // display: flex;
    // justify-content: space-between;
    width: 1190px;
    margin: 20px auto 0;
    font-size: 14px;

    .content {
      width: 100%;
    }

    // .rightcont {
    //   width: 200px;
    // }

    .leftcont {
      width: 1190px;
      // box-shadow: 0px 5px 14px 0 #ddd;
      // padding: 20px;
      margin: 0 auto 20px;
      min-height: 73vh;

      .lcont {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .continfo {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 20px;
      position: relative;
      background-color: #f0f8fc;
      padding: 20px;
      border-radius: 6px;

      img {
        width: 180px;
        height: 180px;
        border-radius: 10px;
      }

      .infobox {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 80%;
        min-height: 180px;

        .boxtop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 22px;
          font-weight: bold;
          // margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;

          span {
            color: #000;
          }
        }

        .infos {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 24px;
        }
      }
    }
  }

  .cinfo1 {

    position: absolute;
    left: 80px;
    bottom: 10px;
    display: flex;
    flex-wrap: nowrap;

    .cdiv {
      border: 1px solid #00A0E9;
      padding: 6px;
      color: #00A0E9;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .cinfo {

    position: absolute;
    right: 20px;
    bottom: 10px;
    display: flex;
    flex-wrap: nowrap;

    .cdiv {
      border: 1px solid #00A0E9;
      padding: 6px;
      color: #00A0E9;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 20px;
    }
  }

  .search {
    display: flex;
    justify-content: flex-start;
    ;
    margin-bottom: 20px;

    .demo-input-suffix {
      margin-right: 20px;
    }
  }


  .news_list {
    width: 100%;
    overflow: hidden;
    display: flex;
    border-top: 1px solid #00A0E9;
    border-left: 1px solid #00A0E9;
    // border-bottom: 1px solid #00A0E9;
    margin-bottom: 10px;

    .cur {
      color: #fff;
      background-color: #00A0E9;
    }

    span {
      font-size: 16px;
      color: #000;
      background: #fff;
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      padding-left: 0;
      border: none;
      position: relative;
      cursor: pointer;
      border-right: 1px solid #00A0E9;
      border-bottom: 1px solid #00A0E9;
    }
  }

  .statusdiv {
    width: 70px;
    background: #00A0E9;
    height: 30px;
    line-height: 30px;
    color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
  }



  .icontext {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    background-color: #00A0E9;
    color: #fff;
    display: inline-block;
    margin-right: 20px;

  }

  .iname {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px;
    line-height: 30px;
  }

  .inames {
    width: 40%;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
  }

  .activecur {
    background-color: #f0f8fc;
  }

  .datacontV {
    height: 70vh !important;
  }

  .datacont {
    overflow-y: auto;

    .linedata {
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px;
      justify-content: space-around;
    }
  }

  .cvinfo {
    display: flex;
    .c_infos{
      display: flex;
      flex-wrap: wrap;
      width: 90%;

      .cw100 {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 10px;

        .w100 {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

        }

        div {
          width: 50%;
          color: #333;
        }
      }
    }
    .c_info {
      display: flex;
      flex-wrap: wrap;
      width: 60%;

      .cw100 {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 10px;

        .w100 {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

        }

        div {
          width: 50%;
          color: #333;
        }
      }

    }

    .cr100 {
      width: 40%;

      .cw100 {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        color: #333;
      }

    }

    .atitle {
      font-weight: 700;
      padding-bottom: 10px;
      display: inline-block;
      color: #000;
    }
  }
</style>
